export default function toWhatsAppNumber(waNumber: string) {
    if (!waNumber) return
    
    let result = waNumber

    if (result.startsWith('0')) result = '62' + result.slice(1)

    return result
}

export {
    toWhatsAppNumber
}
